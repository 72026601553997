 
import SocketClient, { CHAT_SOCKET_EVENTS } from './Socket';
import config from './config'; 

export default class WebChatStore { 

  static #inited = false;
  static #currentSession = {}; 
  static windowStatus = 'closed';

  static setCurrentSession= (data) =>{
    this.#currentSession = data;
    this.components.messenger.current.sessionUpdated();
  }

  static getCurrentSession = () =>{
    return  this.#currentSession;
  }

  static getCurrentSessionId = () =>{
    return  (this.#currentSession || {} ).id;
  }
 
  static #currentUser = {};
  static setCurrentUser= (data) =>{
    this.#currentUser = data;
  }
  static getCurrentUser = () =>{
    return  this.#currentUser;
  }

  static components = {
    button: null,
    bubble: null,
    messenger: null
  }


  static #messages = [];
  static setMessages = (data) =>{
    this.#messages = data;
  } 
  static getMessages = () =>{
    return  this.#messages;
  }

 


  static messageUpdated = (message) =>{ 
    let messages = this.getMessages();
    let found = messages.find(m=>m.ui_id==message.ui_id);
    console.log('messageUpdated', message, found);
    this.setMessages([...messages.filter(m=>m.ui_id!=message.ui_id), message].sort((a,b)=>b.time-a.time));
    this.components.messenger.current.messageUpdated(message);
}
  static showMessenger = () =>{
    this.components.messenger.current.show(this.getCurrentSession(), this.getMessages());
    this.readNotificaitons(); 
    window.parent.postMessage({type:'open', data:{} },'*');
    this.openChatWindow();

    this.components.bubble.current.hide();

    this.windowStatus = 'opened';
  }

  static hideMessenger = () =>{
    this.components.messenger.current.hide(this.getCurrentSession(), this.getMessages());
    this.messengerHidden();
  }
  static messengerHidden = () =>{ 
    window.parent.postMessage({type:'close', data:{} },'*');
    this.closeChatWindow();
    this.windowStatus = 'closed';
  }
 

  static showNotification = () =>{ 
    this.components.bubble.current.show();
    
  } 


  static disableChat = () =>{   
    window.parent.postMessage({
       type:'disable', 
       data:{ 
       }
     },'*');
 } 

  static resizeWindow = (width, height) =>{   
     window.parent.postMessage({
        type:'resize', 
        data:{
            width:width,
            height:height 
        }
      },'*');
  } 

  static #loaded = false;
  static appLoaded = () =>{ 
    if(this.#loaded){
      return;
    }
    this.#loaded = true;

    window.parent.postMessage({type:'load', data:{} },'*');
  }
 

  


  static onNewMessage = (session, message) =>{ 
    this.setMessages([message, ...this.getMessages()].sort((a,b)=>b.time-a.time));
    this.setCurrentSession(session);
    this.messageReceived(message);
    console.log('new message', message);
    this.components.messenger.current.messageUpdated(message);
    if(session.open_status=='CLOSED'){
      this.components.button.current.notify(session.total_unread);

    }
  } 
  
  static fileUploaded = () =>{ 
    SocketClient.sendEvent(CHAT_SOCKET_EVENTS.FILE_UPLOADED, {});
  } 
  static readNotificaitons = () =>{ 
    SocketClient.sendEvent(CHAT_SOCKET_EVENTS.READ_NOTIFICATION, {});
    this.components.button.current.readNotifications();
  } 

  static initChat = () =>{
    SocketClient.sendEvent(CHAT_SOCKET_EVENTS.INIT_CHAT, {});
  }   

  static startSession = () =>{
    SocketClient.sendEvent(CHAT_SOCKET_EVENTS.START, {}); 
  } 

  static endSession = () =>{ 
    SocketClient.sendEvent(CHAT_SOCKET_EVENTS.END, {});
  }



  static openChatWindow = () =>{ 
    SocketClient.sendEvent(CHAT_SOCKET_EVENTS.USER_OPENED, { session_id: this.getCurrentSessionId() });
  } 

  static closeChatWindow = () =>{ 
    SocketClient.sendEvent(CHAT_SOCKET_EVENTS.USER_CLOSED, { session_id: this.getCurrentSessionId() });
  }

  static onChatInited = (initData) =>{
    let lastSession = {};
    if(initData.sessions && initData.sessions.length){
      lastSession = initData.sessions[initData.sessions.length-1];
    }
    if(lastSession.total_unread){
      this.components.button.current.notify(lastSession.total_unread);
    }
   
    this.setMessages((initData.messages || []).sort((a,b)=>b.time-a.time));
    if(initData.openChat){
      this.showMessenger();
    }
    if(lastSession){
      this.setCurrentSession(lastSession);
    } 
    if(initData.user){
      console.log(initData.user.token)
      this.setCurrentUser(initData.user);
      window.localStorage.setItem('token', initData.user.token);
    }

    if(lastSession && lastSession.open_status=='OPEN'){
       
        this.showMessenger();
      
    } 

  } 

  static onUserJoined = (session, user) =>{ 

  }  
  static onNotification = (session, notification) =>{ 

  }


  
  static readNotifications = (notifications) =>{ 
    SocketClient.sendEvent(CHAT_SOCKET_EVENTS.READ_NOTIFICATION, {  session_id: this.getCurrentSessionId(), notifications:notifications });
  }
  static onNotificationsRead = (session, notifications) =>{ 
    this.setCurrentSession(session);
    this.components.button.current.notify(0);
  }



  static startSession = () =>{ 
    SocketClient.sendEvent(CHAT_SOCKET_EVENTS.START, {   });
  }
  static onSessionStarted= (session, user) =>{ 
    this.setCurrentSession(session);
  }



  static messageReceived = (message) =>{ 
    SocketClient.sendEvent(CHAT_SOCKET_EVENTS.MESSAGE_RECEIVED, { session_id: this.getCurrentSessionId(), message:message });
  }
  static onMessageReceived = (session, message) =>{ 

    console.log('messageUpdated', message);
    this.messageUpdated(message);
    this.setCurrentSession(session);
  }
    
    
  static endSession = (message) =>{ 
    SocketClient.sendEvent(CHAT_SOCKET_EVENTS.END, { session_id: this.getCurrentSessionId() });
  }
  static onSessionEnded = (session , user) =>{ 
    this.setCurrentSession(session);
  }



  static sendMessageFileUploaded = (message, content, progress) =>{ 



    let found = this.getMessages().find(m=>m.ui_id==message.ui_id);
    if(!found){
      console.log('not found', message);
      return;
    }

    found.uploadProgress = progress;
    found.uploading = true;
    found.content = content;
    if(progress>=100){
      found.uploading = false;
    }
    this.messageUpdated(found);
    
    SocketClient.sendEvent(CHAT_SOCKET_EVENTS.FILE_UPLOADED, { session_id: this.getCurrentSessionId(),  message:found });
     
  }


  static sendMessage = (message) =>{ 
    console.log('send message' , message);
    let messageToSend = { ...message};
    if(messageToSend.type=='image' && messageToSend.content.startsWith('data:')){
      messageToSend.content = 'uploading...';
    }
    SocketClient.sendEvent(CHAT_SOCKET_EVENTS.MESSAGE, { session_id: this.getCurrentSessionId(),  message:messageToSend });
    
    setTimeout(() => {
      let found =  this.getMessages().find(m=>m.ui_id==message.ui_id);
      if(!found){
        return;
      }
      if(found.status==MESSAGE_STATUS.SENDING){
        console.log('aaaaa')
        this.messageUpdated({...found, status:MESSAGE_STATUS.FAILED});
      }
    }, 5000);
  }
  static onMessageSent = (session, message) =>{   

    console.log('messageUpdated', message);
    this.messageUpdated(message);
    this.setCurrentSession(session);
  }


  static messageRead = (message) =>{ 
    SocketClient.sendEvent(CHAT_SOCKET_EVENTS.MESSAGE_READ, { session_id: this.getCurrentSessionId(), message:message });
  }
  static onMessageRead = (session, message) =>{
    
    console.log('messageUpdated', message); 
    this.messageUpdated(message);
    this.setCurrentSession(session);
  }


  static #lastTypingSent = 0;
  static startTyping = () =>{ 
    if(this.#lastTypingSent + 2000 < new Date().getTime()){
      this.#lastTypingSent = new Date().getTime();
      SocketClient.sendEvent(CHAT_SOCKET_EVENTS.START_TYPING, { session_id: this.getCurrentSessionId() });
    }
    else{
      return;
    }
  }
  static onUserTyping = (session, user) =>{ 
    this.components.messenger.userIsTyping();
  }
  
  static init (  ) { 
    if(this.#inited){
      return;
    }
    this.#inited = true; 
    let token = window.localStorage.getItem('token');
    let application_id  = window.location.search.split('app_id=')[1].split('&')[0];
     

    if(!application_id || application_id=='undefined' || application_id=='null'){
       application_id = window.localStorage.getItem('application_id');
    } 
    
    if(!application_id || application_id=='undefined' || application_id=='null'){
      application_id = '';
   }
    window.localStorage.setItem('application_id', application_id);
   
    console.log('initing ', token, application_id)
    
    SocketClient.init(config.SOCKET_URL, application_id, token);
  }






  static registerComponents ( button, bubble, messenger ) { 
    this.components = {
      button,
      bubble,
      messenger
    }
  }
}
  
 

 



export const MESSAGE_STATUS  = {
  SENDING:'SENDING', 
  FAILED:'FAILED', 
  SENT:'SENT', 
  DELIVERED:'DELIVERED', 
  READ:'READ'
   
}
 
 