
import { io } from "socket.io-client";
import config from "./config";
import WebChatStore from "./WebChat.Store";



export const CHAT_SOCKET_EVENTS = {
  START : 'start',
  END : 'end',
  PING : 'ping',
  WELCOME : 'welcome',
  JOIN : 'join',
  MESSAGE : 'message',
  INIT_CHAT:'init_chat',
  FILE_UPLOADED:'file_uploaded',
  READ_NOTIFICATION : 'read_notification',
  START_TYPING : 'start_typing',
  MESSAGE_RECEIVED : 'message_received',
  MESSAGE_READ : 'message_read',
  USER_OPENED : 'user_opened',
  USER_CLOSED : 'user_closed',




  ON_NOTIFY:'notify',
  ON_STARTED : 'started',
  ON_ENDED : 'ended',
  ON_JOINED: 'joined',   
  ON_CHAT_INITED:'chat_inited',
  ON_NEW_MESSAGE:'new_message',
  ON_MESSAGE_RECEIVED : 'message_received',
  ON_MESSAGE_SENT:'message_sent',
  ON_MESSAGE_READ:'message_read',
  ON_USER_TYPING:'user_typing',
  ON_NOTIFICATION_READ : 'notification_read',
 
  
}


export default class SocketClient {
  static socket = null;
  static init (url, application_id, token) {
    
    this.socket = io(url ,
        { 
          auth: { 
            token: token, 
            application_id:application_id,
            analytics_session_id:window.__shpnw?.data?.sid,
            analytics_visitor_id:window.__shpnw?.data?.vid
          },

      }
    );
    
    this.socket.on('connect', () => {
      console.log('connected');
    });

    this.socket.on('disconnect', () => {
      console.log('disconnected');
    });

    this.socket.on(CHAT_SOCKET_EVENTS.ON_STARTED, (...args) => {
      WebChatStore.onSessionStarted(args[0].session, args[0].user);
    });
    this.socket.on(CHAT_SOCKET_EVENTS.ON_ENDED, (...args) => {
      WebChatStore.onSessionEnded(args[0].session, args[0].user);
    });

    this.socket.on(CHAT_SOCKET_EVENTS.ON_NEW_MESSAGE, (...args) => {
      console.log('args[0]', args[0]);
      WebChatStore.onNewMessage(args[0].session, args[0].message);
    }); 


    this.socket.on(CHAT_SOCKET_EVENTS.ON_JOINED, (...args) => {
      WebChatStore.onUserJoined(args[0].session, args[0].user);
    }); 

    this.socket.on(CHAT_SOCKET_EVENTS.ON_NOTIFY, (...args) => {
      WebChatStore.onNotification(args[0].session, args[0].notification);
    }); 



    this.socket.on(CHAT_SOCKET_EVENTS.ON_CHAT_INITED, (...args) => {
      console.log(CHAT_SOCKET_EVENTS.ON_CHAT_INITED, args);
      WebChatStore.onChatInited(args[0]); 
    }); 


    this.socket.on(CHAT_SOCKET_EVENTS.ON_MESSAGE_SENT, (...args) => {
      console.log(args);
      WebChatStore.onMessageSent(args[0].session, args[0].message);
    });  
    this.socket.on(CHAT_SOCKET_EVENTS.ON_MESSAGE_RECEIVED, (...args) => {
      WebChatStore.onMessageReceived(args[0].session, args[0].message);
    });

    this.socket.on(CHAT_SOCKET_EVENTS.ON_MESSAGE_READ, (...args) => {
      WebChatStore.onMessageRead(args[0].session, args[0].message);
    }); 

    this.socket.on(CHAT_SOCKET_EVENTS.ON_USER_TYPING, (...args) => {
      WebChatStore.onUserTyping(args[0].session, args[0].user);
    }); 
 
    this.socket.on(CHAT_SOCKET_EVENTS.ON_NOTIFICATION_READ, (...args) => {
      WebChatStore.onNotificationsRead(args[0].session, args[0].notifications);
    }); 
  
  }

  static sendEvent(event, data) {
    this.socket.emit(event, data);
  }


 

  static async uploadFile(file) {
    var fileData = new FormData();
    fileData.append('uploaded_file', file);  
 
    let apiResult = await fetch(config.UPLOAD_URL, {
      method: 'POST',
      body: fileData
    });
    return apiResult.json();
  }

  static   getImageUrl(url) {
    if(url.startsWith('/')) {
      return config.FILE_URL+url
    }
    return url;
    
  }

  


}