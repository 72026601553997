 
import './MemberCardItem.css';

function MemberCardItem(props) {

  const showDetail=  () =>{ 
    if(props.onClick){
      props.onClick(props.item);
    }
  }
  
  return (
    <div className="membercard-item clickable" style={{backgroundImage:'url('+props.item.bg+')'}} onClick={()=>showDetail()}>
        <div className="membercard-logo" style={{backgroundImage:'url('+props.item.logo+')'}}></div>
        <div className="membercard-name">{props.item.name}</div>
        <div className="membercard-brand">{props.item.brand}</div>

        <div className="membercard-number">{props.item.number}</div>
        <div className="membercard-desc">{props.item.desc}</div> 
    </div>
  );
}

export default MemberCardItem;
