 
import './Icon.css';

function Icon(props) {
  const clicked = () =>{
    
  }
  return (
    <div className={"app-icon icon-"+props.name + (props.clickable?' clickable ':' ') + (props.className || '')} onClick={props.onClick || clicked} ></div>
    // <div className={"app-icon material-symbols-sharp icon-"+props.name + (props.clickable?' clickable ':' ') + (props.className || '')} onClick={props.onClick || clicked} >{props.name}</div>
  );
} 
export default Icon;
