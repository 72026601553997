 
import { forwardRef, useImperativeHandle, useRef, useState } from 'react';
import Message from './Message';
import './MessageRating.css';
import Icon from './Icon';

const MessageRating = forwardRef((props,ref) => {
   
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState('');
  const [commented, setCommented] = useState(false);
  const starClicked = (rating) => {
   
      setRating(rating);
    if(props.onRatingChanged){
      props.onRatingChanged(rating, comment);
    }
    
  }
  const onSendComment = () => {
    setCommented(true);
    if(props.onRatingChanged){
      props.onRatingChanged(rating, comment);
    }
  }

  return ( 
      <div className='message-voting'>
        <div className={'message-voting-stars '+ (rating>0? ' rated ':'')}>

          <Icon name='star-full' className={"rating-star clickable " + (rating>=5?' selected ':'')} onClick={()=>starClicked(5)} />
          <Icon name='star-full' className={"rating-star clickable " + (rating>=4?' selected ':'')} onClick={()=>starClicked(4)} />
          <Icon name='star-full' className={"rating-star clickable " + (rating>=3?' selected ':'')} onClick={()=>starClicked(3)} />
          <Icon name='star-full' className={"rating-star clickable " + (rating>=2?' selected ':'')} onClick={()=>starClicked(2)} />
          <Icon name='star-full' className={"rating-star clickable " + (rating>=1?' selected ':'')} onClick={()=>starClicked(1)} />
        </div>

        {commented &&
        <div className='message-voting-comment'>{comment}</div> 
        }
        <div className='message-voting-thank'>Thank you for your feedback</div>
        {!commented &&
         <>
        <textarea placeholder='Add your comments' disabled={rating==0} className='message-voting-text' value={comment || ''} onChange={(e)=> setComment(e.target.value) } />
        <div className='button message-voting-button' disabled={rating==0 || !comment?.length}  onClick={()=>onSendComment()}>COMMENT</div>
        </>}
      </div>
       
  );
});

export default MessageRating;
