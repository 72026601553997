 
import './ProductItem.css';

function ProductItem(props) {
  const showDetail=  () =>{
    window.showProduct(props.item);
    if(props.onClick){
      props.onClick(props.item);
    }
  }
  return (
    <div className="product-item clickable" onClick={()=>showDetail()}>
        <div className="product-image" style={{backgroundImage:'url('+props.item.image+')'}}></div>
        <div className="product-name">{props.item.name}</div>
        <div className="product-desc">{props.item.desc}</div>
        {props.item.oldPrice && <div className="product-old-price">RMB {props.item.oldPrice}.00</div>}
        <div className="product-price">RMB {props.item.price}.00</div> 
    </div>
  );
}

export default ProductItem;
