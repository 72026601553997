

import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Icon from './Icon';
import Loading from './Loading';
import MessageList from './MessageList';
import './MediaGallery.css';
import SocketClient, { CHAT_SOCKET_EVENTS } from '../Socket';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Thumbs, Zoom } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const MediaGallery = forwardRef((props, ref) => {


  const [items, setItems] = useState(props.items);
  const [selectedItem, setSelectedItem] = useState(props.selectedItem);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const [visible, setVisible] = useState(false);
  const [thumbsSwiper, setThumbsSwiper] = useState(null);

  useImperativeHandle(ref, () => ({
    show, hide
  }));

  const show = (items, currentItem) => {
    setLoading(true);
    setVisible(true);
  
    let selectedMedia = currentItem;
    if(!selectedMedia || (selectedMedia.type!='image' && selectedMedia.type=='video')){
      selectedMedia = null;
    }
    let selectedIndex = 0; 
    let ind = 0;
    let medias = items.filter((i, index) => {
    
      if(i.type=='image' || (i.type || '').toLowerCase()=='video'){
        i.content = SocketClient.getImageUrl(i.content);
       
        if(selectedMedia && i.id==selectedMedia.id){
          selectedIndex = ind;
        }
        ind++;
        return true;
      }
    });

    if(!selectedMedia){
      selectedIndex = 0; 
      selectedMedia = medias[selectedIndex];
    }
    setSelectedIndex(selectedIndex);
    setItems(medias);
    setSelectedItem(selectedMedia);
    load();
    console.log('medias',medias);
  }

  const hide = (item) => {
    setVisible(false);
    setLoading(true);
  }

 

  const load = () => {
    const to_id = setTimeout(() => {
      setLoading(false);
    }, 1000);
     
  }
  // const [zoomed, setZoomed] = useState(false);

  // const zoomImage = (e) =>{
 
  //   setZoomed(!zoomed);
  //   return;

  //   if(e.target.style.transform){
  //     e.target.style='';
  //    // e.target.parentElement.style='';
  //     return;
  //   }
  //  // e.target.parentElement.style='';
  //   console.log(e);
  //   let rect = e.target.getClientRects();
  //   let sizes = {w:rect[0].width, h:rect[0].height};
  //   let clickedPos = {y: e.clientY-rect[0].top, x:e.clientX-rect[0].left};
    
  //   let percent =  100-(clickedPos.y * 100 / sizes.h);
    
   
  //   console.log(clickedPos.y * 3)
  //   e.target.style='transition:1s; transform: scale(3) translate(0%, 50%); margin-top: -50%; position: fixed; top: 0; left: 0; right: 0; bottom: 0; background: white; z-index: 1000; '; 
    
  // }

   if(!visible){
    return null;
   }
  
  return (<>
  {loading?<Loading visible={visible} />:null}
  <div className={"messenger-media-gallery rounded " + (loading?' loading ':'')}>

    
    {/* {zoomed?<div className='zoomed rounded'  >
     
      <img src={items[selectedIndex].content || '/noimage.png'} />
    </div>:null} */}
    <Icon name="cross" onClick={hide} className="messenger-media-gallery-close clickable" />
    
    
    {/* {
      zoomed?
      <Icon name="zoom-out" onClick={zoomImage} className="messenger-media-gallery-zoom clickable" />
      :
      <Icon name="zoom-in" onClick={zoomImage} className="messenger-media-gallery-zoom clickable" />
    } */}
   
    <Swiper
      spaceBetween={50}
      navigation
      loop={true}
      initialSlide={selectedIndex}
      slidesPerView={1}
      freeMode={true}
      zoom={true}
      watchSlidesProgress={true}
      pagination={{ clickable: true }}

      modules={[Zoom, Thumbs, Navigation]}
      thumbs={{swiper: thumbsSwiper && !thumbsSwiper.destroyed ? thumbsSwiper : null}}
      className="messenger-media-gallery-main"

    >
      {(items || []).map(i=>{
        return  <SwiperSlide className='messenger-media-gallery-item'>

          {i.type=='image'  &&<div className='swiper-zoom-container'> <img className='rounded' src={i.content || '/noimage.png'}  /></div>}
          {(i.type || '').toLowerCase()=='video'  && <video className='rounded' src={i.content || '/noimage.png'} controls> </video>}

        </SwiperSlide>
      })}
     
    </Swiper>


    <Swiper
      modules={[Thumbs]}
       
      onSwiper={setThumbsSwiper}
     slidesPerView={'auto'}
     spaceBetween={16} 
      
       className="messenger-media-gallery-thumbs"
    >
      
      {(items || []).map(i=>{
        return  <SwiperSlide className='messenger-media-gallery-thumbs-item rounded'>
           {(i.type || '').toLowerCase()=='image'  && <img src={i.content || '/noimage.png'}  />}
           {(i.type || '').toLowerCase()=='video'  && <video  src={i.content} controls={false}> </video>}
        </SwiperSlide>
      })}
    </Swiper>

  </div>
  </>
  );
});

export default MediaGallery;
