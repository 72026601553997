 
 
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Icon from './Icon';
import MessageList from './MessageList';
import './MessengerBubble.css';   
import SocketClient, { CHAT_SOCKET_EVENTS } from '../Socket';
import MediaGallery from './MediaGallery';
import WebChatStore from '../WebChat.Store';

const MessengerBubble = forwardRef((props,ref)=> {

 

  useImperativeHandle(ref, () => ({ 
    show ,
    hide
  }));
     

  const [displayed, setDisplayed] = useState(false);
  const [visible, setVisible] = useState(false);
  const [text, setText] = useState(props.text);
  

  const hide = () =>{  
    setDisplayed(false);
  } 
  

  const show = (txt) =>{  
    setDisplayed(true);
    setText(txt || props.text)
  } 
  
  
  const onClicked = () =>{
    if(props.onClick){
      props.onClick();
    }
  }

  const resizeWindow = (pos) =>{
   
    setVisible(true);

    
    let bubbleSize = document.querySelector('.messenger-bubble').getClientRects()[0];
    let buttonSize = document.querySelector('.messenger-button').getClientRects()[0];

  
    if(pos=='up'){ 
     
         WebChatStore.resizeWindow(
            bubbleSize.width+32, 
            bubbleSize.height + buttonSize.height + 72
        ); 
    }
    else{
       

        WebChatStore.resizeWindow(
            bubbleSize.width + buttonSize.width + 68, 
            (bubbleSize.height + 44)< (buttonSize.height + 44) ? (buttonSize.height + 44) : (bubbleSize.height + 44)
        ); 
    }
      
  }

  const resizeWindowRef = useRef(resizeWindow);

  useEffect(() => {
     setText(props.text);
  },[props.text]);

  useEffect(() => {
    if(displayed){
      setTimeout(() => {
        let pos = props.position=='up'?'up':'bottom';
        resizeWindowRef.current(pos);
      }, 300); 
    }
    else{
      setVisible(false);
    }
  },[displayed, props.position]);

  if(!displayed){
    return null;
  }
  if(displayed){
    setTimeout(() => {
      let pos = props.position=='up'?'up':'bottom';
      resizeWindowRef.current(pos);
    }, 300); 
  }
  
  return ( 
    
      <div className={"messenger-bubble clickable rounded padding " + (props.position=='up'?'up':'bottom') + (visible?' visible ':' ')} onClick={onClicked} 
      dangerouslySetInnerHTML={{ __html:text || props.text || 'Please add some text or hide notification'}}>
        
      </div>
     
  );
});

export default MessengerBubble;
