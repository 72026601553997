 
 
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Icon from './Icon';
import MessageList from './MessageList';
import './MessengerButton.css';   
import SocketClient, { CHAT_SOCKET_EVENTS } from '../Socket';
import MediaGallery from './MediaGallery';

const MessengerButton = forwardRef((props,ref)=> {

 

  useImperativeHandle(ref, () => ({ 
    notify ,
    readNotifications
  }));
     

  
  const [notifications, setNotifications] = useState(0);
   
 
 
  const readNotifications = () =>{  
    setNotifications(0);
  } 
 
  const notify = (notifications) =>{  
    setNotifications(notifications);
  } 
 
  
  const onClicked = () =>{
  
    if(props.onClick){
      props.onClick();
    }
     
  }



  return ( 
    
      <div className="messenger-button clickable" onClick={onClicked} >
        <Icon name="chat_bubble" />
        {notifications>0 && <div className="messenger-button-notifications">{notifications}</div>}
      </div>
     
  );
});

export default MessengerButton;
