 
import { forwardRef, useImperativeHandle, useRef } from 'react';
import Message from './Message';
import './MessageList.css';
import Icon from './Icon';
import MessageRating from './MessageRating';

const MessageList = forwardRef((props,ref) => {
  
  useImperativeHandle(ref, () => ({ 
    scrollToEnd
  }));
    
  const listRef = useRef();

  const scrollToEnd = (item) =>{  
     if(listRef && listRef.current) {
      listRef.current.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
     }

  } 


  return (
    <div className="message-list padding" ref={listRef}>
       
       {props.sender_typing && 
        <Message 
        csrName={props.csrName}
        csrAvatar={props.csrAvatar}
          key={'typing'} item={{id:'11', type:'///' , typing:true }} />
        }


      {props.notification?
      <div  className={"notification-message padding rounded "} 
            dangerouslySetInnerHTML={{ __html:props.notification}}>
      </div>:null}
        
      {props.items.map(i=><Message key={i.id} item={i} onMessageClicked={props.onMessageClicked}  onMessageResend={props.onMessageResend} /> )} 
         

      
    </div>
  );
});

export default MessageList;
