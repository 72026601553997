

import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import Icon from './Icon';
import MessageList from './MessageList';
import './Loading.css';
import SocketClient, { CHAT_SOCKET_EVENTS } from '../Socket';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y, Thumbs } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';


const Loading = forwardRef((props, ref) => {

 


  return (<div className="messenger-loading rounded padding">
      <img src="/loading.svg" />
      Loading...
  </div>
  );
});

export default Loading;
